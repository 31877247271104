import React, { useRef, useEffect } from "react";
import { TweenLite } from "gsap";

const Sketch = () => {
	const sketch = useRef(null);

	useEffect(() => {
		TweenLite.from(sketch.current, 1, { opacity: 0, delay: 2 });
	});
	return (
		<svg
			width="359"
			height="643"
			viewBox="0 0 359 643"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="sketch"
			ref={sketch}
		>
			<path
				d="M144.184 31.282C124.765 89.8928 105.174 148.504 85.7544 207.114C82.3173 217.427 75.4432 230.318 76.9899 241.49C78.3647 250.772 85.9262 253.522 94.1751 254.725C124.077 259.366 154.324 262.803 184.226 266.928C186.288 267.272 187.835 265.897 187.319 263.834C173.055 185.973 158.62 107.94 144.356 30.0788C143.841 26.8131 138.857 28.1882 139.372 31.4539C153.636 109.315 168.072 187.348 182.335 265.21C183.367 264.178 184.398 263.147 185.429 262.116C158.792 258.506 131.983 254.897 105.346 251.287C100.018 250.6 93.6596 250.6 88.6758 248.537C79.3958 244.756 81.8017 235.646 84.2077 228.255C88.3321 215.193 92.8003 202.13 97.2685 189.239C114.626 136.816 131.811 84.7364 149.168 32.657C150.199 29.5632 145.215 28.1882 144.184 31.282Z"
				fill="#1A653D"
			/>
			<path
				d="M242.656 78.8925C214.644 77.0018 186.46 86.2833 165.322 105.018C158.276 111.206 145.559 122.894 150.371 133.722C155.355 145.066 174.774 140.253 183.882 139.738C203.13 138.706 222.549 137.16 241.797 135.441C258.466 133.894 276.683 133.894 292.665 129.081C302.976 125.987 312.944 118.425 314.147 107.081C315.35 96.0804 306.929 88.3459 297.649 84.2208C282.869 77.6894 264.309 77.6894 248.499 79.0644C230.282 80.6113 212.582 85.5958 196.084 93.3304C193.162 94.7054 195.74 99.1743 198.662 97.7992C214.816 90.2365 232.173 85.4239 250.046 84.2208C264.309 83.1895 280.292 83.1895 293.868 88.3459C300.226 90.7522 307.101 95.0492 308.819 102.096C311.397 113.268 300.57 121.347 291.29 124.269C275.823 129.081 257.779 129.081 241.797 130.456C224.783 132.003 207.77 133.206 190.756 134.238C182.507 134.753 172.712 136.644 164.635 135.613C147.965 133.378 156.901 120.831 163.26 114.128C183.71 92.8147 213.269 81.9863 242.484 84.0489C245.921 84.2208 245.921 79.0644 242.656 78.8925Z"
				fill="#1A653D"
			/>
			<path
				d="M123.218 88.6896C97.4404 88.174 71.8344 91.9553 47.6032 100.549C36.2609 104.503 24.7468 109.315 14.2638 115.675C8.07706 119.456 1.20296 124.784 0.171846 132.691C-5.32744 175.317 105.861 137.503 122.359 131.488C125.452 130.284 124.078 125.3 120.984 126.503C102.08 133.378 82.8329 138.878 63.2417 142.66C52.0713 144.722 40.7291 146.613 29.5586 147.3C23.8875 147.644 18.2164 147.816 13.0608 145.238C5.32742 141.457 3.26519 133.378 8.59262 126.847C14.2638 119.8 24.4031 115.847 32.4801 112.237C41.932 107.94 51.8995 104.331 62.0388 101.581C81.9737 96.0804 102.596 93.5023 123.218 94.0179C126.483 93.846 126.483 88.6896 123.218 88.6896Z"
				fill="#1A653D"
			/>
			<path
				d="M56.8832 114.643C46.2283 114.643 38.1513 126.503 42.2757 136.472C46.2283 146.441 59.9765 148.332 68.0536 142.488C77.1618 135.956 76.4744 122.034 69.2566 114.471C60.1484 105.19 44.6817 108.284 37.1202 117.737C35.0579 120.315 38.6668 123.925 40.7291 121.347C45.7128 114.987 54.9928 111.378 62.5543 115.847C69.2566 119.8 72.0062 129.941 67.0225 136.3C62.5543 141.972 52.5869 142.488 48.1187 136.644C43.135 130.113 48.8061 119.972 56.7113 119.972C60.1484 119.8 60.1484 114.643 56.8832 114.643Z"
				fill="#1A653D"
				className="left-eye"
			/>
			<path
				d="M208.285 106.049C197.631 108.112 191.616 120.659 195.74 130.456C200.208 141.113 214.472 143.347 223.752 137.675C234.579 131.144 235.266 115.847 228.22 106.221C220.143 95.0492 204.161 94.3617 193.162 101.065C190.241 102.784 192.819 107.253 195.74 105.534C203.989 100.721 215.503 100.206 222.549 107.424C228.908 113.956 229.939 125.987 222.721 132.175C216.706 137.16 205.364 137.16 201.239 129.769C197.115 122.55 201.755 112.581 209.832 111.034C212.925 110.518 211.551 105.534 208.285 106.049Z"
				fill="#1A653D"
				className="right-eye"
			/>
			<path
				d="M302.289 160.363C307.444 153.832 312.944 146.269 321.192 143.519C332.191 139.738 340.268 146.441 342.33 156.926C345.424 172.051 343.19 189.067 343.19 204.364C343.19 212.615 343.19 220.693 343.19 228.943C343.19 237.537 344.049 246.99 341.815 255.241C338.378 267.444 325.489 274.147 315.693 263.663C309.678 257.131 308.475 247.85 307.272 239.428C306.757 236.162 301.773 237.537 302.289 240.803C303.835 250.944 305.726 261.944 313.975 268.991C319.99 274.147 328.238 276.038 335.456 272.257C343.533 267.96 347.142 258.85 348.173 250.256C349.72 237.365 348.689 223.787 348.689 210.896C348.689 197.145 348.861 183.567 348.689 169.817C348.689 161.395 348.345 151.941 343.19 145.066C329.613 126.331 308.475 144.55 298.68 156.926C296.618 159.332 300.398 162.941 302.289 160.363Z"
				fill="#1A653D"
			/>
			<path
				d="M329.613 171.364C328.067 168.098 324.973 166.551 321.536 167.238C316.381 168.27 314.662 173.082 313.803 177.551C312.428 185.973 312.772 195.083 312.256 203.677C311.912 209.864 310.022 218.458 313.459 224.13C318.099 231.865 329.441 228.943 332.019 221.208C335.972 209.005 322.739 202.13 312.944 200.927C309.678 200.583 309.678 205.739 312.944 206.083C316.381 206.599 319.818 207.114 322.567 209.349C324.114 210.036 325.145 211.24 325.661 212.958C328.41 215.708 327.379 217.427 322.224 217.771C314.834 221.208 316.896 219.146 316.896 213.646C316.896 210.896 317.24 208.146 317.412 205.396C317.755 200.411 317.927 195.598 318.271 190.614C318.443 188.38 318.443 186.317 318.615 184.083C318.615 182.192 318.786 180.301 319.13 178.582C318.786 175.317 320.849 173.598 325.317 173.77C326.52 176.864 330.988 174.285 329.613 171.364Z"
				fill="#1A653D"
			/>
			<path
				d="M54.821 26.1256C88.5041 22.5162 122.359 18.9067 156.042 15.4691C187.491 12.2034 219.112 7.73454 250.733 5.67199C276.683 3.9532 307.101 6.70327 320.333 32.8289C327.035 46.0636 328.067 61.5327 329.098 75.9706C330.301 92.6428 331.332 109.315 332.535 125.987C332.707 129.253 337.862 129.253 337.69 125.987C335.972 100.721 336.315 74.4237 331.332 49.6731C327.551 30.4226 317.412 13.9222 298.852 6.01575C279.948 -1.89069 258.466 -0.515657 238.531 1.54689C209.66 4.46884 181.133 7.56266 152.605 10.6565C119.953 14.0941 87.3011 17.5317 54.6491 20.9692C51.5558 21.313 51.3839 26.4694 54.821 26.1256Z"
				fill="#1A653D"
			/>
			<path
				d="M39.1823 195.942C46.4001 267.1 53.7898 338.43 61.0076 409.588C61.1795 410.791 62.0387 412.338 63.5854 412.166C143.669 404.26 223.065 389.65 300.914 368.509C301.945 368.165 302.804 367.134 302.804 366.103C303.492 339.461 304.179 312.82 304.866 286.179C304.866 282.913 299.711 282.913 299.711 286.179C299.023 312.82 298.336 339.461 297.649 366.103C298.336 365.243 298.852 364.384 299.539 363.696C222.205 384.666 143.325 399.103 63.5854 407.182C64.4447 408.041 65.3039 408.901 66.1632 409.76C58.9454 338.602 51.5557 267.272 44.3379 196.114C43.9942 192.677 38.8386 192.677 39.1823 195.942Z"
				fill="#1A653D"
			/>
			<path
				d="M148.996 397.041C148.824 414.057 148.481 431.073 148.309 448.261C148.137 461.496 146.075 476.793 150.027 489.684C156.214 510.137 180.789 513.403 195.396 499.309C203.302 491.746 207.254 481.09 208.285 470.433C209.488 458.917 209.316 443.104 203.645 432.62C199.521 425.057 190.069 418.698 181.476 422.823C174.946 426.088 172.368 434.339 176.493 440.526C178.383 443.276 182.851 440.698 180.961 437.948C178.383 434.167 180.961 428.839 184.742 427.12C187.147 425.917 191.616 427.635 193.678 429.01C198.49 432.276 200.724 438.12 201.755 443.448C203.473 452.042 203.989 461.324 202.958 470.09C201.068 487.106 188.007 508.247 167.9 502.059C148.996 496.387 152.949 470.09 153.293 454.964C153.636 435.714 153.808 416.291 154.152 397.041C154.152 393.603 148.996 393.603 148.996 397.041Z"
				fill="#1A653D"
			/>
			<path
				d="M114.626 258.85C114.626 243.897 129.577 232.381 144.184 235.131C158.104 237.881 167.041 250.944 168.931 264.178C169.447 267.444 174.43 266.069 173.915 262.803C171.509 246.131 158.104 229.974 140.232 229.631C124.078 229.287 109.642 242.693 109.47 259.022C109.47 262.288 114.626 262.288 114.626 258.85Z"
				fill="#1A653D"
			/>
			<path
				d="M101.393 318.32C102.08 292.71 141.95 296.663 159.135 295.976C169.103 295.46 179.242 294.257 189.21 295.117C200.896 296.148 210.176 301.992 219.456 308.523C219.628 306.976 219.799 305.429 220.143 303.882C199.864 309.211 179.414 314.539 159.135 319.867C140.403 324.852 119.781 330.867 101.221 321.93C98.2996 320.555 95.55 324.852 98.6433 326.399C115.485 334.649 133.873 331.555 151.402 327.258C174.774 321.414 198.146 315.055 221.518 308.867C223.752 308.351 223.924 305.429 222.205 304.226C212.066 297.179 202.099 291.163 189.381 290.132C174.946 288.929 159.995 290.991 145.559 291.507C126.999 292.366 96.9248 293.054 96.4092 318.492C96.2374 321.586 101.393 321.586 101.393 318.32Z"
				fill="#1A653D"
			/>
			<path
				d="M99.6745 333.102C101.049 344.102 102.94 356.993 113.595 363.009C122.531 367.993 130.092 364.556 136.623 357.681C138.341 355.962 139.716 353.727 141.607 352.352C146.59 348.399 150.371 350.462 155.527 352.524C160.167 354.415 164.635 355.274 169.619 354.243C179.07 352.352 186.46 345.477 189.038 336.196C189.897 332.93 184.913 331.555 184.054 334.821C181.304 344.446 171.337 351.321 161.37 349.087C154.495 347.54 149.168 342.383 141.95 345.821C135.592 348.743 133.014 356.993 126.484 359.399C110.673 365.415 106.033 343.758 104.83 333.102C104.486 329.836 99.3308 329.836 99.6745 333.102Z"
				fill="#1A653D"
			/>
			<path
				d="M270.668 191.473C273.074 199.208 275.823 207.458 275.136 215.708C274.62 223.271 271.011 229.802 267.574 236.334C261.731 247.162 257.263 258.163 254.857 270.366C250.389 293.226 253.139 316.601 262.247 337.914C263.622 341.008 267.918 338.258 266.715 335.336C256.232 310.929 255.029 283.257 263.45 257.991C267.231 246.475 274.62 236.678 278.401 225.162C282.354 213.302 279.089 201.614 275.48 190.098C274.62 187.005 269.637 188.208 270.668 191.473Z"
				fill="#1A653D"
			/>
			<path
				d="M87.4729 461.839C40.5572 470.09 15.9823 521.997 29.5586 565.482C45.7128 616.702 97.7841 642.828 148.824 643.172C251.592 643.687 335.284 556.545 354.016 459.777C366.218 396.869 355.047 331.211 328.238 273.46C326.864 270.538 322.395 273.116 323.77 276.038C366.562 367.993 366.905 483.324 299.195 563.592C270.496 597.452 231.829 623.406 188.35 633.547C139.029 644.891 80.427 635.265 48.8061 592.296C32.9957 570.639 25.606 542.451 34.1986 516.497C42.2757 491.746 62.898 471.636 88.8478 467.168C92.113 466.308 90.7381 461.324 87.4729 461.839Z"
				fill="#1A653D"
			/>
		</svg>
	);
};

export default Sketch;
